import requests from './requests'

export default {
  applications: {
    get (token) {
      return requests.get(`applications/${token}`)
    },
    save_application (application, data) {
      return requests.patch(`applications/${application.token}`, null, data)
    },
    submit_application (application, data) {
      return requests.post(`applications/${application.token}`, null, data)
    },
    resend_verify_link (application) {
      return requests.post(`applications/${application.token}/resend-verify-link`, null)
    }
  }
}
