import Axios from 'axios'

const BASE = '/api/portal'

export default {
  getConfiguration: () => ({
    headers: { }
  }),
  prepareUrl (path, qry) {
    const target = `${BASE}/${path}`

    if (qry) {
      const qs = Object.keys(qry)
        .map(k => [encodeURIComponent(k), encodeURIComponent(qry[k])].join('='))
        .join('&')

      return `${target}?${qs}`
    } else {
      return target
    }
  },
  async get (path, qry) {
    return (await Axios.get(this.prepareUrl(path, qry), this.getConfiguration())).data
  },
  async head (path, qry) {
    return (await Axios.head(this.prepareUrl(path, qry), this.getConfiguration())).data
  },
  async put (path, qry, data) {
    return (await Axios.put(this.prepareUrl(path, qry), data, this.getConfiguration())).data
  },
  async patch (path, qry, data) {
    return (await Axios.patch(this.prepareUrl(path, qry), data, this.getConfiguration())).data
  },
  async post (path, qry, data) {
    return (await Axios.post(this.prepareUrl(path, qry), data, this.getConfiguration())).data
  },
  async delete (path, qry) {
    return (await Axios.delete(this.prepareUrl(path, qry), this.getConfiguration())).data
  }
}
