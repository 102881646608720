<template>
  <div v-if="application === null">
    <div class="container my-4">
      <div class="notification is-primary" v-if="loading">
        <span class="icon"><fi icon="spinner" spin /></span> Loading application form...
      </div>

      <div class="notification is-danger" v-if="error">
        <strong>Error:</strong> {{error}}
      </div>
    </div>
  </div>
  <div v-else-if="application.submitted_on !== null">
    <section class="hero is-medium is-success">
      <div class="hero-head">
        <div class="level-right p-5">
          <img src="@/assets/logo-light.svg" alt="Catalyst EPS" width="200px" class="level-item" />
        </div>
      </div>

      <div class="hero-body">
        <p class="title">
          New Customer Account Application Submitted
        </p>
        <p class="subtitle">
          <template v-if="application.verified_on === null">
            Your application has been submitted, but you must verify your email address.
          </template>
          <template v-else>
            Your application has been submitted, and will be reviewed by a member of the team.
          </template>
        </p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="notification is-warning" v-if="application.verified_on === null">
          You must verify your email address ({{applicationData.agree.email}}) by following the link that was sent to the address you used to submit your application.
          If you haven't received it, check your spam folder, otherwise <a href="#" @click.prevent="resendVerify">click here</a> to resend the verification link.  If your
          email address is incorrect, please contact <strong>{{application.issuer.name}}</strong> at <a :href="`mailto:${application.issuer.email}`">{{application.issuer.email}}</a>.
        </div>
        <div class="notification is-success" v-else>
          You do not need to do anything else.
        </div>
        <div class="notification is-success" v-if="linkResent">
          The verification link has been resent.
        </div>
      </div>
    </section>
  </div>
  <div v-else>
    <section class="hero is-medium is-dark">
      <div class="hero-head">
        <div class="level-right p-5">
          <img src="@/assets/logo-light.svg" alt="Catalyst EPS" width="200px" class="level-item" />
        </div>
      </div>

      <div class="hero-body">
        <p class="title">
          New Customer Account Application<template v-if="application.status === 'revision'"> - Revisions Requested</template>
        </p>
        <p class="subtitle">
          This new customer application form is for the attention of <strong>{{application.applicant.name}}</strong> ({{application.applicant.email}}).
        </p>
        <p>You are invited to make revisions to your application, and resubmit.</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <p class="block">
          Please complete this form with as much detail as possible.  Any incomplete or
          missing information may cause this form to be returned to you, and therefore
          delay your application.  If you need help at any point, please don't hesitate
          to get in touch with your account manager <strong>{{application.issuer.name}}</strong> on <a :href="`mailto:${application.issuer.email}`">{{application.issuer.email}}</a>.</p>

        <p class="block">As part of the application process, we collect your personal data.  Refer to our <a href="https://catalyst-eps.com/privacy-policy">privacy policy</a> to
          see how we use your data.  By continuing with this application, and entering data on this page, you consent for us to process your data in accordance
          with this policy.</p>

        <p class="block">Fields marked with an <span class="has-text-danger">*</span> are required.</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h1 class="title">Company Information</h1>

        <div class="field">
          <label class="label"><span class="has-text-danger">*</span> Official Company Name (or your full name, if you're a private individual):</label>
          <div class="control">
            <input class="input" type="text" placeholder="Company Name" v-model.trim="applicationData.companyName" required>
          </div>
        </div>

        <div class="field">
          <label class="label">Trading Name (if different):</label>
          <div class="control">
            <input class="input" type="text" placeholder="Trading Name" v-model.trim="applicationData.tradingName">
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label"><span class="has-text-danger">*</span> Type of business:</label>
              <div class="select">
                <select v-model="applicationData.businessType">
                  <option :value="null" disabled>-- Select an option --</option>
                  <option value="private">Private Individual</option>
                  <option value="sole-trader">Sole Trader</option>
                  <option value="partnership">Partnership</option>
                  <option value="llp">Limited Liability Partnership</option>
                  <option value="ltd">Private Limited Company</option>
                  <option value="plc">Public Limited Company</option>
                  <option value="charity">Registered Charity</option>
                </select>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field" v-if="!isPrivateIndividual">
              <label class="label"><span class="has-text-danger">*</span> Number of years trading:</label>
              <div class="select">
                <select v-model="applicationData.yearsTrading">
                  <option :value="null" disabled>-- Select an option --</option>
                  <option :value="0">Less than 1 year</option>
                  <option :value="1">1 -- 2 years</option>
                  <option :value="2">2 -- 5 years</option>
                  <option :value="5">5 -- 10 years</option>
                  <option :value="10">More than 10 years</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="field" v-if="!isPrivateIndividual">
          <label class="checkbox">
            <input type="checkbox" v-model="applicationData.vatRegistered">
            VAT Registered
          </label>
        </div>

        <div class="columns">
          <div class="column" v-if="enableRegNo">
            <div class="field">
              <label class="label"><span class="has-text-danger">*</span> Company Registration Number:</label>
              <div class="control">
                <input class="input" type="text" placeholder="Company registration number" v-model.trim="applicationData.companyRegNo">
              </div>
            </div>
          </div>
          <div class="column" v-if="enableVatNo">
            <div class="field">
              <label class="label"><span class="has-text-danger">*</span> VAT Registration Number:</label>
              <div class="control">
                <input class="input" type="text" placeholder="VAT registration number" v-model.trim="applicationData.vatRegNo">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h1 class="title">Contact Details</h1>

        <div class="columns">
          <div class="column">
            <p class="label"><span class="has-text-danger">*</span> Billing Contact:</p>
            <contact-input :contact="applicationData.contact.billing" />
          </div>
          <div class="column">
            <p class="label">Delivery Contact:</p>
            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="applicationData.contact.delivery.as_billing">
                Same as billing
              </label>
            </div>
            <contact-input :contact="applicationData.contact.delivery" />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <p class="label">Accounts Payable:</p>
            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="applicationData.contact.accounts_payable.as_billing">
                Same as billing
              </label>
            </div>
            <contact-input :contact="applicationData.contact.accounts_payable" />
          </div>
          <div class="column">
            <p class="label">Order Queries:</p>
            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="applicationData.contact.order_queries.as_billing">
                Same as billing
              </label>
            </div>
            <contact-input :contact="applicationData.contact.order_queries" />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h1 class="title">Credit Application</h1>
        <div class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="applicationData.credit.apply">
            I would like to make an application for a credit account
          </label>
        </div>

        <template v-if="applicationData.credit.apply">
          <p class="block">To apply for a credit account, please provide details for <b>TWO</b> credit references:</p>
          <div class="columns">
            <div class="column">
              <p class="label"><span class="has-text-danger">*</span> Referee 1:</p>
              <credit-reference :referee="applicationData.credit.r1" />
            </div>

            <div class="column">
              <p class="label"><span class="has-text-danger">*</span> Referee 2:</p>
              <credit-reference :referee="applicationData.credit.r2" />
            </div>
          </div>
        </template>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h1 class="title">Supporting Documents</h1>
        <p class="block">You must upload the following documents to support your application:</p>

        <div class="level-left" v-if="!requirePhotoId">
          <p class="level-item">Company letterhead:</p>
          <div class="level-item">
            <div class="file">
              <label class="file-label">
                <input class="file-input" type="file" name="resume">
                <span class="file-cta">
                  <span class="file-icon">
                    <fi icon="upload" />
                  </span>
                  <span class="file-label">
                    Choose a file…
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="level-left" v-if="requirePhotoId">
          <p class="level-item">Photographic ID:</p>
          <div class="level-item">
            <div class="file">
              <label class="file-label">
                <input class="file-input" type="file" name="resume">
                <span class="file-cta">
                  <span class="file-icon">
                    <fi icon="upload" />
                  </span>
                  <span class="file-label">
                    Choose a file…
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="level-left mt-4" v-if="requirePhotoId">
          <p class="level-item">Other form of ID:</p>
          <div class="level-item">
            <div class="file">
              <label class="file-label">
                <input class="file-input" type="file" name="resume">
                <span class="file-cta">
                  <span class="file-icon">
                    <fi icon="upload" />
                  </span>
                  <span class="file-label">
                    Choose a file…
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="section">
      <div class="container">
        <h1 class="title">Submission</h1>

        <div class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="applicationData.agree.declaration">
            I hereby declare that the above information blah blah
          </label>
        </div>

        <div class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="applicationData.agree.terms">
            By submitting this application, I agree to XXX
          </label>
        </div>

        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label"><span class="has-text-danger">*</span> Name:</label>
              <div class="control">
                <input class="input" type="text" placeholder="Name" v-model.trim="applicationData.agree.name" required>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label"><span class="has-text-danger">*</span> Position:</label>
              <div class="control">
                <input class="input" type="text" placeholder="Position" v-model.trim="applicationData.agree.position" required>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label"><span class="has-text-danger">*</span> Email Address:</label>
          <div class="control">
            <input class="input" type="email" placeholder="Email Address" v-model.trim="applicationData.agree.email" required>
          </div>
        </div>

        <div class="notification is-warning" v-if="applicationData.agree.email !== application.applicant.email">
          You have changed the email address associated with this application, and so will require to complete
          a verification step before your application is fully submitted.
        </div>

        <hr>

        <div class="notification is-danger" v-if="!formValid">
          The application form is currently incomplete.  Please review all fields and
          ensure you have filled them in.
        </div>

        <div class="notification is-warning" v-if="formValid && !agreementValid">
          You must complete the declaration to submit your applicationData.
        </div>

        <div class="notification is-success" v-if="canSubmit">
          Your application form is ready to submit.
        </div>

        <div class="field is-grouped">
          <div class="control">
            <button class="button is-primary is-medium" :disabled="!canSave || saving" :class="{'is-loading': saving}" @click="save">
              <span class="icon"><fi icon="save" /></span> <span>Save Application</span>
            </button>
          </div>

          <div class="control">
            <button class="button is-success is-medium" :disabled="!canSubmit || submitting" :class="{'is-loading': submitting}" @click="submit">
              <span class="icon"><fi icon="check" /></span> <span>Submit Application</span>
            </button>
          </div>
        </div>

        <div class="notification is-danger" v-if="submissionError">
          An error occurred whilst submitting your application, and the submission failed.  Please contact
          your account manager.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// AXdVdNQmdJI12t8DYdoFfgvjI7LoMPZY

import ContactInput from './ContactInput'
import CreditReference from './CreditReference'

export default {
  components: { ContactInput, CreditReference },
  data: () => ({
    loading: false,
    error: null,
    submitting: false,
    submissionError: null,
    saving: false,
    application: null,
    linkResent: false,
    applicationData: {
      companyName: '',
      tradingName: '',
      businessType: null,
      vatRegistered: false,
      companyRegNo: '',
      vatRegNo: '',
      yearsTrading: null,
      credit: {
        apply: false,
        r1: {
          company_name: '',
          credit_limit: '',
          contact: {
            as_billing: false,
            name: '',
            telephone: '',
            email: '',
            address: {
              line1: '',
              line2: '',
              line3: '',
              town: '',
              county: '',
              postcode: ''
            }
          }
        },
        r2: {
          company_name: '',
          credit_limit: '',
          contact: {
            as_billing: false,
            name: '',
            telephone: '',
            email: '',
            address: {
              line1: '',
              line2: '',
              line3: '',
              town: '',
              county: '',
              postcode: ''
            }
          }
        }
      },
      contact: {
        billing: {
          as_billing: false,
          name: '',
          telephone: '',
          email: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postcode: ''
          }
        },
        delivery: {
          as_billing: false,
          name: '',
          telephone: '',
          email: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postcode: ''
          }
        },
        accounts_payable: {
          as_billing: false,
          name: '',
          telephone: '',
          email: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postcode: ''
          }
        },
        order_queries: {
          as_billing: false,
          name: '',
          telephone: '',
          email: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postcode: ''
          }
        }
      },
      agree: {
        declaration: false,
        terms: false,
        name: '',
        position: '',
        email: ''
      }
    },
    canSave: true
  }),
  watch: {
    'applicationData.businessType' (t) {
      if (t === 'private') {
        this.applicationData.yearsTrading = null
        this.applicationData.vatRegistered = false
        this.applicationData.companyRegNo = ''
      }
    },
    'applicationData.vatRegistered' (vr) {
      if (vr === false) {
        this.applicationData.vatRegNo = ''
      }
    }
  },
  computed: {
    isPrivateIndividual () {
      if (this.applicationData.businessType === null) {
        return true
      }

      switch (this.applicationData.businessType) {
        case 'private':
          return true

        default:
          return false
      }
    },
    enableRegNo () {
      if (this.applicationData.businessType === null) {
        return false
      }

      switch (this.applicationData.businessType) {
        case 'sole-trader':
        case 'partnership':
        case 'private':
          return false

        default:
          return true
      }
    },
    enableVatNo () {
      return this.applicationData.vatRegistered
    },
    requirePhotoId () {
      if (this.applicationData.businessType === null) {
        return false
      }

      switch (this.applicationData.businessType) {
        case 'sole-trader':
        case 'partnership':
        case 'private':
          return true

        default:
          return false
      }
    },
    companyInfoValid () {
      if (this.applicationData.companyName === '') {
        return false
      }

      if (this.applicationData.businessType === null) {
        return false
      }

      if (this.applicationData.businessType !== 'private' && this.applicationData.yearsTrading === null) {
        return false
      }

      if (this.enableRegNo && this.applicationData.companyRegNo === '') {
        return false
      }

      if (this.applicationData.vatRegistered && this.applicationData.vatRegNo === '') {
        return false
      }

      return true
    },
    contactDetailsValid () {
      if (!this.isContactValid(this.applicationData.contact.billing)) {
        return false
      }

      if (!this.isContactValid(this.applicationData.contact.delivery)) {
        return false
      }

      if (!this.isContactValid(this.applicationData.contact.accounts_payable)) {
        return false
      }

      if (!this.isContactValid(this.applicationData.contact.order_queries)) {
        return false
      }

      return true
    },
    creditApplicationValid () {
      if (this.applicationData.credit.apply === false) {
        return true
      }

      if (this.applicationData.credit.r1.company_name === '' || this.applicationData.credit.r2.company_name === '') {
        return false
      }

      return this.isContactValid(this.applicationData.credit.r1.contact) && this.isContactValid(this.applicationData.credit.r2.contact)
    },
    supportingDocumentsValid () {
      return true
    },
    formValid () {
      return this.companyInfoValid && this.contactDetailsValid && this.creditApplicationValid && this.supportingDocumentsValid
    },
    canAgree () {
      return this.formValid
    },
    agreementValid () {
      return this.applicationData.agree.declaration &&
        this.applicationData.agree.terms &&
        this.applicationData.agree.name !== '' &&
        this.applicationData.agree.email !== '' &&
        this.applicationData.agree.position !== ''
    },
    canSubmit () {
      return this.formValid && this.agreementValid
    }
  },
  methods: {
    async reload () {
      const urlSearchParams = new URLSearchParams(this.$route.hash.substring(1))
      const params = Object.fromEntries(urlSearchParams.entries())

      if (params.t === undefined || params.t === null || params.t === '') {
        this.error = 'Invalid invitation link - please double-check you have entered the URL correctly.'
        return
      }

      this.loading = true
      try {
        this.application = await API.applications.get(params.t)
        this.applicationData = Object.assign({}, this.applicationData, this.application.data)

        // this.application.agree.name = this.invitation.applicant.name
        // this.application.agree.email = this.invitation.applicant.email
      } catch (error) {
        this.error = 'Unable to load application form'
      } finally {
        this.loading = false
      }
    },
    isContactValid (contact) {
      if (contact.as_billing === true) {
        return true
      }

      if (contact.name === '') {
        return false
      }

      if (contact.email === '') {
        return false
      }

      return true
    },
    async save () {
      this.saving = true
      try {
        await API.applications.save_application(this.application, this.applicationData)
        this.reload()
      } catch (error) {
        alert(error)
      } finally {
        this.saving = false
      }
    },
    async submit () {
      this.submitting = true
      this.submissionError = null
      try {
        await API.applications.submit_application(this.application, this.applicationData)
        this.reload()
      } catch (error) {
        this.submissionError = error
      } finally {
        this.submitting = false
      }
    },
    async resendVerify () {
      try {
        await API.applications.resend_verify_link(this.application)
        this.linkResent = true
      } catch (error) {
        alert(error)
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
