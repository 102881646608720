<template>
  <div>
    <div class="field">
      <div class="control has-icons-left">
        <input class="input is-small" :class="{ 'is-danger': !companyNameValid }" type="text" placeholder="Company Name" v-model.trim="referee.company_name">
        <span class="icon is-small is-left">
          <fi icon="building" />
        </span>
      </div>

      <p class="help is-danger" v-if="!companyNameValid">You must enter a company name for this referee.</p>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <input class="input is-small" type="text" placeholder="Credit limit" v-model.trim="referee.credit_limit">
        <span class="icon is-small is-left">
          <fi icon="pound-sign" />
        </span>
      </div>
    </div>

    <contact-input :contact="referee.contact" />
  </div>
</template>

<script>
import ContactInput from './ContactInput.vue'

export default {
  props: ['referee'],
  components: { ContactInput },
  computed: {
    companyNameValid () {
      return this.referee.company_name.length > 0
    }
  }
}
</script>
