<template>
  <div v-if="!contact.as_billing">
    <div class="field">
      <div class="control has-icons-left">
        <input class="input is-small" :class="{ 'is-danger': !nameValid }" type="text" placeholder="Contact Name" v-model.trim="contact.name" required>
        <span class="icon is-small is-left">
          <fi icon="user" />
        </span>
      </div>
      <p class="help is-danger" v-if="!nameValid">You must enter a name for this contact.</p>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <input class="input is-small" :class="{ 'is-danger': !emailValid }" type="email" placeholder="Email Address" v-model.trim="contact.email">
        <span class="icon is-small is-left">
          <fi icon="at" />
        </span>
      </div>
      <p class="help is-danger" v-if="!emailValid">You must enter an email address for this contact.</p>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <input class="input is-small" type="text" placeholder="Telephone Number" v-model="contact.telephone">
        <span class="icon is-small is-left">
          <fi icon="phone" />
        </span>
      </div>
    </div>

    <address-input :address="contact.address" />
  </div>
</template>

<script>
import AddressInput from './AddressInput.vue'

export default {
  props: ['contact'],
  components: { AddressInput },
  computed: {
    nameValid () {
      return this.contact.name.length > 0
    },
    emailValid () {
      return this.contact.email.length > 0
    }
  }
}
</script>
