<template>
  <div>
    <div class="field">
      <div class="control has-icons-left">
        <input class="input is-small" type="text" placeholder="Address Line 1" v-model="address.line1">
        <span class="icon is-small is-left">
          <fi icon="address-card" />
        </span>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="control">
            <input class="input is-small" type="text" placeholder="Address Line 2" v-model="address.line2">
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <div class="control">
            <input class="input is-small" type="text" placeholder="Address Line 3" v-model="address.line3">
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="control">
            <input class="input is-small" type="text" placeholder="Town" v-model="address.town">
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <div class="control">
            <input class="input is-small" type="text" placeholder="County" v-model="address.county">
          </div>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <input class="input is-small" type="text" placeholder="Post Code" v-model="address.postcode">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['address']
}
</script>
